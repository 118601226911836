
import React from 'react';

import PropTypes from 'prop-types';
import { uniqueID } from '../../../Utils/Utils'

import './BlockValuesTwoCol.scss';

let classNames = require('classnames');

const BlockValuesTwoCol = ({
  textBlock,
  isLast = false,
  color
}) => {
  return (
    <div
      className={classNames(
        'block_values',
        isLast ? 'last' : ''
      )}
    >
      <div className={classNames("details_values", color === 'bleu' ? 'color_white' : 'color_dark')}>
        <div
          className="details"
          dangerouslySetInnerHTML={{
            __html:
              textBlock.field_bloc_text_1?.processed,
          }}
        ></div>
        <div className="values">
          <div
            className="txt"
            dangerouslySetInnerHTML={{
              __html:
                textBlock.field_bloc_text_2?.processed,
            }}
          />
        </div>
      </div>
    </div>
  );
};

BlockValuesTwoCol.propTypes = {
  textBlock: PropTypes.object,
  isLast: PropTypes.bool
};

export default BlockValuesTwoCol;



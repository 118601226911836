import React from 'react';
import { Button } from '../../../../Molecules/Button/Button'
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';

var classNames = require('classnames');

const CtaSection = ({ ctaDetails, customClass = '', sectionClass = '' }) => {

  let ctaData = ctaDetails.content[0];

  return (
    <section className={classNames('section_content', 'reports_container', customClass, sectionClass)}>
      <div className="wrapper_page">
        <TitleBlock
          headingTag="h3"
          title={ctaData.title}
          type="arrow"
          iconColor="color_dark_bleu"
        />
        <div style={{ marginLeft: '38px' }}>
          <Button
            label={ctaData.button_title}
            link={ctaData.link}
            target={ctaData.target}
          />
        </div>
      </div>
    </section>
  );
};

export default CtaSection;

import * as React from 'react';

import PropTypes from 'prop-types';

import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { Button } from '../../../Molecules/Button/Button';
import './BlockTextTwoColButton.scss';

let classNames = require('classnames');

const BlockTextTwoColButton = ({ title, section_marge, body_text_1, body_text_2, cta }) => {
  const { type, text_title, subtitle, color } = title || {};
  const { label, link, primary, cta_position } = cta || {}
  return (
    <div className={classNames('block_text_two_col_button', { 'section_marge': section_marge })}>
      {
        text_title && type === 'section_title' ? (
          <TitleSection
            title={text_title}
            subtitle={subtitle}
            color={color}
          />
        ) : type === 'arrow' ? (
          <TitleBlock
            title={text_title}
          />
        ) : text_title ? (
          <h3 className={`color_${color}`} dangerouslySetInnerHTML={{ __html: text_title }} />
        ) : (<></>)
      }
      <div className='text_content'>
        {(body_text_1 && !body_text_2) && <div className={`color_${color}`} dangerouslySetInnerHTML={{ __html: body_text_1 }} ></div>}
        {(body_text_2 && !body_text_1) && <div className={`color_${color}`} dangerouslySetInnerHTML={{ __html: body_text_2 }} ></div>}
        {
          (body_text_1 && body_text_2) && (
            <div className={`two_col_text_cta color_${color}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: body_text_1,
                }}
              />
              <div>
                <div dangerouslySetInnerHTML={{
                  __html: body_text_2,
                }} />
                {
                  (link && label && cta_position === 'center') &&
                  <Button
                    label={label}
                    primary={primary}
                    link={link}
                  />
                }
              </div>
            </div>
          )
        }
        {
          (link && label && cta_position != 'center') &&
          <Button
            label={label}
            primary={primary}
            link={link}
          />
        }
      </div>
    </div>
  );
};

BlockTextTwoColButton.propTypes = {
  title: PropTypes.object,
  description: PropTypes.string
};

export default BlockTextTwoColButton;

import React from "react";
import "./BlockSimpleTextVisuelButtonWebsite.scss";
import TitleBlock from "../../../Molecules/TitleBlock/TitleBlock";
import TitleSection from "../../../Molecules/TitleSection/TitleSection";
import useMedias from '../../../hooks/useMedias';
import { Button } from "../../../Molecules/Button/Button";
import VisuelComponent from "../VisuelComponent/VisuelComponent";
import { getSectionBG, getTextColor } from "../../../Utils/Utils";

const BlockSimpleTextVisuelButtonWebsite = ({ data, imagesArray }) => {
  const { getImage } = useMedias();
  return (
    <div className={`block_simple_text_visuel_button_website`}>
      {
        data.field_title_type === 'theme_2' ?
          <TitleBlock
            title={data.field_title?.processed}
            subtitle={data.field_subtitle?.processed}
            rtl={data.field_display_rtl}
            color={'color_' + getTextColor(getSectionBG(data.field_backgroud_color))}
            headingTag='h3'
            titleBackground={false}
          />
          :
          <TitleSection
            title={data.field_title?.processed}
            subtitle={data.field_subtitle?.processed}
            rtl={data.field_display_rtl}
            color={getTextColor(getSectionBG(data.field_backgroud_color))}
            theme={data.field_title_type}
          />
      }
      {
        data.body?.processed &&
        <div className={`arrowlist_component color_${getTextColor(getSectionBG(data.field_backgroud_color))} ${data.field_display_rtl ? 'rtl rtl-text' : ''}`}
          dangerouslySetInnerHTML={{
            __html: data.body?.processed,
          }
          }
        ></div >
      }
      {data.field_link?.title && data.field_link?.url && (
        <Button
          label={data.field_link?.title}
          link={data.field_link?.url}
          aria-label={data.field_link?.title}
        />
      )}
      {
        data.relationships?.field_image &&
        <VisuelComponent
          extremeLeft={
            data.field_no_padding &&
              data.field_alignment === 'left'
              ? true
              : false
          }
          extremeRight={
            data.field_no_padding &&
              data.field_alignment === 'right'
              ? true
              : false
          }
          visuel={getImage(
            imagesArray,
            data.relationships?.field_image?.drupal_internal__mid
          )}
          size="two_third"
          alt={data.relationships?.field_image?.field_media_image?.alt || ''}
        />
      }
    </div >
  )
}

export default BlockSimpleTextVisuelButtonWebsite;

import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import Combobox from "react-widgets/Combobox";

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import { Button } from '../../../Molecules/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../components/Carousel/Carousel';
import DocumentSection from '../../../components/ContentType/Section/DocumentSection/DocumentSection';
import {
  formatBytes,
  getBreadCrumb,
  getReOrderedCarouselSlides,
  getSectionBG,
  getTextColor,
  removeHtmlTag,
  tradDroiteGauche,
  uniqueID,
} from '../../../Utils/Utils';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { FormattedMessage, useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VideoSection from '../../../components/ContentType/Section/VideoSection/VideoSection';
import useMedias from '../../../hooks/useMedias';
import StickyJobApply from '../../../Molecules/StickyJobApply/StickyJobApply';
import CardSection from '../../../components/ContentType/CardSection/CardSection';
import ContactInfo from '../../../components/ContentType/ContactInfo/ContactInfo';
import HomeColasInnov from '../../../components/HomeColasInnov/HomeColasInnov';
import PopinVideo from '../../../components/PopinVideo/PopinVideo';
import CtaSection from '../../../components/ContentType/Section/CtaSection/CtaSection';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import BlockValuesTwoCol from '../../../components/ContentType/BlockValuesTwoCol/BlockValuesTwoCol';
import TitleContact from '../../../Molecules/TitleContact/TitleContact';
import ContactFormDefaultPage from '../../../components/ContactForm/ContactFormDefaultPage';
import BlockExpertise from '../../../components/BlockExpertise/BlockExpertise';

import './styles.scss';
import BlockTextTwoColButton from '../../../components/ContentType/BlockTextTwoColButton/BlockTextTwoColButton';
import DiscoverOfferBox from '../../../Molecules/DiscoverOfferBox/DiscoverOfferBox';
import DocumentView from '../../../Molecules/DocumentView/DocumentView';
import CommitteeDetail from '../../../components/ContentType/CommitteeDetail/CommitteeDetail';
import ListAdminMembers from '../../../components/ListAdminMembers/ListAdminMembers';
import ListDirectionMember from '../../../components/ContentType/ListDirectionMember/ListDirectionMember';
import HomeJoinUs from '../../../components/HomeJoinUs/HomeJoinUs';
import ListEngagement from '../../../components/ContentType/ListEngagement/ListEngagement';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import BlockSimpleTextVisuelButtonWebsite from '../../../components/ContentType/BlockSimpleTextVisuelButtonWebsite/BlockSimpleTextVisuelButtonWebsite';
import WebsiteList from '../../../components/WebsiteList/WebsiteList';
import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
let classNames = require('classnames');

const DefaultTemplate = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const documentsArray = data.allDocuments.edges;

  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');
  const [videoId, setVideoId] = useState(null);
  const [stopVideo, setStopVideo] = useState(false);
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  const blocks = data.pageData.edges[0].node.relationships.field_blocs;

  const eventBannerData = blocks.find(block => block.__typename === "block_content__block_banniere_evenementielle");

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  let sectionArr = [];
  let sectionCount = 0;
  let globalText = '';

  const slider_settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1, //no slider on desktop
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };
  const slider_why_join_settings = {
    className: 'hash_dots_slider',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    dots: true,
    customPaging: function (i) {
      return (
        <a>
          {`#${i + 1}.`}
          <svg width="36.961" height="6" viewBox="0 0 36.961 6" aria-hidden="true">
            <path
              d="M31.654 4.412A10.487 10.487 0 0126.478 6h-16a10.481 10.481 0 01-5.175-1.588A39.3 39.3 0 01-.001 0h36.963a39.323 39.323 0 01-5.308 4.412z"
              fill="#ffed00"
            ></path>
          </svg>
        </a>
      );
    },
  };

  const loadVideo = (val) => {
    setVideoId(val);
    setStopVideo(false);
    document.body.classList.add('show_video_popin');
  }
  const closePopinVideo = () => {
    document.body.classList.remove('show_video_popin');
    setStopVideo(true);
    if (document.querySelector('a.accessibilite')) {
      document.querySelector('a.accessibilite').focus();
    }
  }

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(
      removeHtmlTag(
        `${pageData.title}.${pageData.field_sous_titre !== null &&
          pageData.field_sous_titre !== undefined
          ? pageData.field_sous_titre
          : ''
        }.${globalText}`
      )
    );
  }, []);

  const linkButtonHandler = (e) => {
    window.open(e.currentTarget.getAttribute('data-url'), '_self');
  };
  const dropdownlist = [];
  const onChangeDropdownBanner = (dropdownSelectedItem) => {
    const block_pos = dropdownlist.indexOf(dropdownSelectedItem);
    if (block_pos > -1) {
      const offset_top = document.querySelector('.scroll_to_' + (block_pos + 1))?.offsetTop - 50;
      window.scrollTo({
        top: offset_top,
        behavior: 'smooth',
      });
    };
  }

  pageData.relationships?.field_blocs?.map((block, i) => {
    if (block?.field_title?.processed) {
      dropdownlist.push(block?.field_title?.processed)
    }
  })

  return (
    <>
      <PopinVideo videoId={videoId} closePopinVideo={closePopinVideo} stopVideo={stopVideo} />
      <Layout
        eventBannerData={eventBannerData ? eventBannerData : null}
        imagesArray={imagesArray}
      >
        <Seo
          title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
          description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
          shareImage={processUrl(getImage(imagesArray, pageData.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_medium)}
        />
        <div
          className={classNames(
            'page_template',
            'page_default',
            !pageData?.field_padding && 'zero_bottom_padding'
          )}
        >
          {
            pageData.field_progress && <ScrollPageNav data={innerNavList} />
          }

          {
            pageData.field_header_inverse ? (
              <PageBannerMap
                showHideClass={false}
                arrowIconeWhite={false}
                visuel={getImage(
                  imagesArray,
                  pageData?.relationships?.field_image?.drupal_internal__mid
                )}
                alt={pageData.relationships?.field_image?.field_media_image?.alt || ''}
                scroll={true}
                breadcrumbData={{
                  parentPage: {
                    title: breadCrumb?.parent?.name,
                    url: breadCrumb?.parent?.link,
                  },
                  currentPage: {
                    title: pageData?.title,
                    url: '',
                  },
                  locale: pageData.langcode,
                }}
                hasDropdown={pageData.field_display_dropdown_list}
              >
                <div className={classNames('wrapper_page', { 'allorw_overflow': (dropdownlist.length > 0 && pageData.field_display_dropdown_list) })}>
                  <div
                    className={classNames({
                      title_audio:
                        allText &&
                        pageData.field_version_audio &&
                        allText.length > 30,
                    })}
                  >
                    <TitlePage
                      color="color_white"
                      title={pageData?.title}
                      description={pageData?.field_sous_titre}
                    />
                    <div className="col_2_fix">
                      <div className="col">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: pageData.body?.processed,
                          }}
                        ></div>
                      </div>
                      <div className="col">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: pageData.field_text_2?.processed,
                          }}
                        ></div>
                      </div>
                    </div>
                    {allText &&
                      pageData.field_version_audio &&
                      allText.length > 30 && (
                        <AudioComponent
                          text={intl.formatMessage({
                            id: 'detailsActivities.button.listen.label',
                          })}
                          textToRead={allText}
                          color="color_white"
                          langCountryCode={pageContext['config_data_' + intl.locale]?.field_lang_and_countrycode}
                        />
                      )}
                    {
                      (dropdownlist?.length > 0 && pageData.field_display_dropdown_list) &&
                      <Combobox
                        defaultValue={intl.formatMessage({ id: 'common.choose' })}
                        data={dropdownlist}
                        onChange={onChangeDropdownBanner}
                        onKeyPress={(e) => { e.preventDefault(); }}
                        filter={false}
                      />
                    }
                    {
                      pageData.field_display_dropdown_list &&
                      <span role="status" className="sr-only"><FormattedMessage id="media.dropdown_sr_only" /></span>
                    }

                  </div>
                </div>
              </PageBannerMap>
            ) : (
              <PageBanner
                visuel={getImage(
                  imagesArray,
                  pageData?.relationships?.field_image?.drupal_internal__mid
                )}
                alt={pageData.relationships?.field_image?.field_media_image?.alt || ''}
                scroll={true}
              >
                <div className="wrapper_page">
                  <Breadcrumb
                    page_banner={true}
                    data={{
                      parentPage: {
                        title: breadCrumb?.parent?.name,
                        url: breadCrumb?.parent?.link,
                      },
                      currentPage: {
                        title: breadCrumb?.current?.name,
                        url: breadCrumb?.current?.link,
                      },
                      locale: pageData.langcode,
                    }}
                  />
                  <div
                    className={classNames({
                      title_audio:
                        allText &&
                        pageData.field_version_audio &&
                        allText.length > 30,
                    })}
                  >
                    <TitlePage
                      color="color_white"
                      title={pageData.title}
                      description={pageData.field_sous_titre}
                    />
                    {allText && pageData.field_version_audio && allText.length > 30 && (
                      <AudioComponent
                        text={intl.formatMessage({
                          id: 'detailsActivities.button.listen.label',
                        })}
                        textToRead={allText}
                        color="color_white"
                        langCountryCode={pageContext['config_data_' + intl.locale]?.field_lang_and_countrycode}
                      />
                    )}
                  </div>
                </div>
              </PageBanner>
            )

          }

          {pageData.relationships?.field_blocs?.map((block, i) => {
            if (block.field_title?.processed) {
              sectionCount++;
              sectionArr.push({
                title: block.field_title?.processed,
                section: 'scroll_to_' + sectionCount,
              });
            }
            switch (block.__typename) {
              case 'block_content__block_contact':
                return (
                  <div
                    key={i}
                    className={classNames(block?.field_type_bloc_contact === 'sticky' ? 'sticky_container_jobs' : '', 'block_content__block_contact')}
                  >
                    {block?.field_type_bloc_contact === 'sticky' && (
                      <StickyJobApply
                        text={block?.body?.processed}
                        buttonLabel={intl.formatMessage({
                          id: 'talent.button.apply.label',
                        })}
                        arialLabel={intl.formatMessage({ id: 'talent.button.apply.label' }) + ' - ' + intl.formatMessage({ id: 'common.new_tab' })}
                        buttonLink={block?.field_link?.url}
                        target={block?.field_nouvelle_fenetre && '_blank'}
                      />
                    )}
                  </div>
                );
              case 'block_content__block_edito':
                globalText += `${block.field_title?.processed}.${block?.field_subtitle?.processed != undefined &&
                  block?.field_subtitle?.processed != null
                  ? block?.field_subtitle?.processed
                  : ''
                  }.${block?.field_citation != undefined &&
                    block?.field_citation != null
                    ? block?.field_citation
                    : ''
                  }.`;

                return (
                  <IntroPage
                    key={i}
                    customClass={block?.field_title?.processed ? 'scroll_to_' + sectionCount : null}
                    sectionClass='block_content__block_edito'
                    title={block?.field_title?.processed}
                    subtitle={block?.field_subtitle?.processed}
                    visuel={getImage(
                      imagesArray,
                      block.relationships?.field_image?.drupal_internal__mid
                    )}
                    alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                    text={null}
                    size="wrapper" //border
                    quote={{
                      lang: block?.langcode,
                      text: block?.field_citation,
                      name: block?.field_link_title,
                    }}
                  />
                );
              case 'block_content__block_expertise':
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_expertise',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                    )}
                    key={i}>
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    >
                      <BlockExpertise
                        title={block?.field_title.processed}
                        subtitle={block?.field_subtitle.processed}
                        text={block?.body?.processed}
                        textList={block?.field_bloc_text_1}
                        color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                      />
                    </ShapeBackground>
                  </section>
                );
              case 'block_content__block_texte':
                globalText += `${block.field_title?.processed}.${block?.field_subtitle?.processed != undefined &&
                  block?.field_subtitle?.processed != null
                  ? block?.field_subtitle?.processed
                  : ''
                  }.${block?.body?.processed != undefined &&
                    block?.body?.processed != null
                    ? block?.body?.processed
                    : ''
                  }.`;
                block?.relationships?.field_section?.forEach((section, j) => {
                  globalText += section?.field_title?.processed || '';
                  globalText += section?.field_bloc_text_1?.processed || '';
                })
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_texte',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                      block.field_secondary_background ? 'zero_margin_bottom' : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                      secondaryColor={getSectionBG(block.field_secondary_background)}
                    >
                      {
                        block.relationships?.field_image?.drupal_internal__mid && block?.body?.processed ? (
                          <IntroPage
                            key={i}
                            sectionClass={null}
                            customClass='zero_margin'
                            title={block?.field_title?.processed}
                            subtitle={block?.field_subtitle?.processed}
                            visuel={getImage(
                              imagesArray,
                              block.relationships?.field_image?.drupal_internal__mid
                            )}
                            alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                            text={block?.body?.processed}
                            color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                            size="border"
                          />
                        ) : (
                          <div className="wrapper_page_xs">
                            <TitleSection
                              title={block?.field_title?.processed}
                              description={block?.field_subtitle?.processed}
                              type="arrow"
                              color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                            />
                          </div>
                        )
                      }

                      <div className="wrapper_page_xs">
                        {block?.relationships?.field_section.map((section, j) => {
                          globalText += `${section?.field_title?.processed != undefined &&
                            section?.field_title?.processed != null
                            ? section?.field_title?.processed
                            : ''
                            }.${section?.field_bloc_text_1?.processed
                            }.`;
                          return (
                            <TextVisuelTwoCol
                              key={j}
                              extremeLeft={
                                section?.field_no_padding &&
                                  section?.field_alignment === 'left'
                                  ? true
                                  : false
                              }
                              extremeRight={
                                section?.field_no_padding &&
                                  section?.field_alignment === 'right'
                                  ? true
                                  : false
                              }
                              orderInverse={
                                section?.field_alignment === 'right'
                                  ? true
                                  : false
                              }
                              extactHalf={
                                section?.field_alignment === 'left' ? true : false
                              }
                              visuel={getImage(
                                imagesArray,
                                section?.relationships?.field_image
                                  ?.drupal_internal__mid
                              )}
                              alt={section?.relationships?.field_image?.field_media_image?.alt || ''}
                              title={section?.field_title?.processed}
                              colorTitle={'color_' + getTextColor(getSectionBG(block?.field_backgroud_color))}
                              titleIconColor={section.field_theme_title === 'theme_1' ? 'color_yellow' : (getSectionBG(block?.field_backgroud_color) === 'bleu' ? 'color_white' : 'color_dark_bleu')}
                              colorDescription={'color_' + getTextColor(getSectionBG(block?.field_backgroud_color))}
                              text={section?.field_bloc_text_1?.processed}
                              cta={{
                                ctaText: section?.field_link?.title,
                                ctaLink: section?.field_link?.url,
                                ctaArialLabel: section?.field_link?.title + (section?.field_title?.processed ? (' ' + intl.formatMessage({ id: 'common.about_something' }) + ' ' + section?.field_title?.processed) : ''),
                                ctaTarget: section?.field_nouvelle_fenetre
                              }}
                            />
                          );
                        })}
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__basic':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}.${block.body?.processed}`;
                const webSiteDetails = {
                  legend: block?.field_legend?.processed,
                  field_sites_web: block?.relationships?.field_sites_web
                }
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__basic',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                      block.field_secondary_background ? 'zero_margin_bottom' : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block.field_backgroud_color)}
                      bottomInfinite={block.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                      secondaryColor={getSectionBG(block.field_secondary_background)}
                    >
                      {(() => {
                        switch (block.field_appearance) {
                          case 'two_column_format':
                            return (
                              <div className="wrapper_page">
                                <TextVisuelCTATwoCol
                                  extremeLeft={
                                    block?.field_no_padding &&
                                      block?.field_alignment === 'left'
                                      ? true
                                      : false
                                  }
                                  color={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                  extremeRight={
                                    block?.field_no_padding &&
                                      block?.field_alignment === 'left'
                                      ? false
                                      : true
                                  }
                                  orderInverse={
                                    block?.field_alignment === 'right' ? true : false
                                  }
                                  visuel={getImage(
                                    imagesArray,
                                    block.relationships?.field_image?.drupal_internal__mid
                                  )}
                                  alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                                  alignTop={true}
                                  title={{
                                    titleSize: block.field_title_type === 'theme_1' ? 'h2' : 'h3',
                                    titleText: block.field_title?.processed,
                                    subtitle: block.field_subtitle?.processed,
                                    titleIconColor: block.field_theme_title === 'theme_1' ? 'color_yellow' : (getSectionBG(block?.field_backgroud_color) === 'bleu' ? 'color_white' : 'color_dark_bleu')
                                  }}
                                  text={block.body.processed}
                                  cta={{
                                    ctaText: block.field_link?.title,
                                    ctaLink: block.field_link?.url,
                                  }}
                                />
                              </div>
                            )
                          case 'two_column_format_title_outside':
                            return (
                              <div className="wrapper_page_xs">
                                {
                                  block.field_title_type === 'theme_2' ?
                                    <TitleBlock
                                      title={block.field_title?.processed}
                                      subtitle={block.field_subtitle?.processed}
                                      color={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                      titleBackground={false}
                                    />
                                    :
                                    <TitleSection
                                      title={block.field_title?.processed}
                                      subtitle={block.field_subtitle?.processed}
                                      color={getTextColor(getSectionBG(block.field_backgroud_color))}
                                    />
                                }
                                <TextVisuelTwoCol
                                  orderInverse={
                                    block.field_alignment === 'right'
                                      ? true
                                      : false
                                  }
                                  extremeLeft={
                                    block?.field_no_padding &&
                                      block?.field_alignment === 'left'
                                      ? true
                                      : false
                                  }
                                  extremeRight={
                                    block?.field_no_padding &&
                                      block?.field_alignment === 'right'
                                      ? true
                                      : false
                                  }
                                  visuel={getImage(
                                    imagesArray,
                                    block?.relationships?.field_image?.drupal_internal__mid
                                  )}
                                  alt={block?.field_title?.processed}
                                  text={block.body.processed}
                                  titleIconColor={block.field_theme_title === 'theme_1' ? 'color_yellow' : (getSectionBG(block?.field_backgroud_color) === 'bleu' ? 'color_white' : 'color_dark_bleu')}
                                  colorTitle={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                  colorDescription={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                  cta={{
                                    ctaText: block.field_link?.title,
                                    ctaLink: block.field_link?.url,
                                  }}

                                />
                              </div>
                            )
                          default:
                            return (
                              <div className="wrapper_page_xs">
                                <BlockSimpleTextVisuelButtonWebsite data={block} imagesArray={imagesArray} />
                              </div>
                            )
                            break;
                        }
                      })()}
                      <div className="wrapper_page">
                        {webSiteDetails?.field_sites_web?.length > 0 &&
                          <WebsiteList
                            componentData={webSiteDetails}
                            imagesArray={imagesArray}
                            alignment={data.imageAlignment}
                            color={getTextColor(getSectionBG(block.field_backgroud_color))}
                          />
                        }
                      </div>

                    </ShapeBackground>
                  </section>
                );
              case 'block_content__block_slider_nos_activites': //Block Slider de contenus existant
                let slides = [];
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                block.relationships?.field_nos_activites?.forEach((item) => {
                  slides.push({
                    image: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    info: {
                      text: item.title,
                      description: item.body?.summary,
                      link: item.field_link?.uri ? item.field_link?.uri : '/' + item.path?.langcode + item.path?.alias,
                      link_label: block.field_link_title,
                    },
                  });
                });


                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_slider_nos_activites',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                      block.field_secondary_background ? 'zero_margin_bottom' : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block.field_backgroud_color)}
                      bottomInfinite={block.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                      secondaryColor={getSectionBG(block.field_secondary_background)}
                    >
                      <div className={classNames('wrapper_page_xs')}>
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          description={block.body?.processed}
                          type="arrow"
                          color={getTextColor(getSectionBG(block.field_backgroud_color))}
                        />
                      </div>
                      <div
                        className={classNames('wrapper_page_xs', 'only_desktop')}
                      >
                        <Carousel
                          slides={getReOrderedCarouselSlides(slides)}
                          textColor={getTextColor(getSectionBG(block.field_backgroud_color))}
                          openVideo={loadVideo}
                          socialShareIcons={pageContext['config_data_' + intl.locale]}
                        />
                      </div>

                      <div className={classNames('only_mobile_tablet')}>
                        <SliderComponent settings={slider_settings}>
                          {slides.map((slide, index) => (
                            <div key={index}>
                              <div className="only_mobile">
                                <div className="image-wrapper">
                                  <img
                                    src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)}
                                    alt={`slide-${index}`}
                                    style={{
                                      width: '100%',
                                      height: '123vw',
                                      maxHeight: '510px',
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="only_tablet">
                                <img
                                  src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)}
                                  alt={`slide-${index}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                              <div className="info_title_description">
                                <p>{slide.info.text}</p>
                                {slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                    aria-label={slide.info.link_label}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_citation':
                globalText += block?.field_citation + '.';
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_citation'
                    )}
                    key={i}>
                    <div className="wrapper_page_xs">
                      <div className="quotemark_container">
                        <QuoteMark
                          lang={block.langcode}
                          size="m"
                          textWeight="m"
                          text={block?.field_citation}
                        />
                      </div>
                    </div>
                  </section>
                );
              case 'block_content__block_cta':
                globalText += `${block.field_title?.processed}.${block?.field_link?.title}`;
                const ctaDetails = {
                  content: [
                    {
                      type: 'cta',
                      title: removeHtmlTag(block?.field_title.processed),
                      link: block?.field_link?.url,
                      target: block?.field_nouvelle_fenetre ? '_blank' : '_self',
                      button_title: block?.field_link?.title,
                    }
                  ]
                }
                return (
                  <CtaSection key={i} ctaDetails={ctaDetails} customClass={'scroll_to_' + sectionCount} sectionClass='block_content__block_cta'></CtaSection>
                );




              case 'block_content__block_slider_innovation': //Block Slider Avec Ancre
                const sliders = [];
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                block.relationships?.field_slides?.forEach((item) => {
                  sliders.push({
                    image: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    alt: item.relationships?.field_image?.field_media_image?.alt || '',
                    info: {
                      text: item.field_title?.processed,
                      link: item.field_link?.url,
                      link_label: block?.field_slider_big ? item.field_link?.title ? item.field_link?.title : intl.formatMessage({ id: 'detailsActivities.findMore' }) : item.field_link?.title ? item.field_link?.title : '',
                      anchor: item?.relationships?.field_anchor ? '?anchor=section-' + item?.relationships?.field_anchor?.drupal_internal__tid : null
                    }
                  });
                });
                sliders.push(...sliders);

                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_slider_innovation',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                      block.field_secondary_background ? 'zero_margin_bottom' : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                      secondaryColor={getSectionBG(block.field_secondary_background)}
                    >
                      <div className={classNames('only_desktop')}>
                        {
                          block?.field_slider_big ? (
                            <HomeColasInnov
                              data={block}
                              imagesArray={imagesArray}
                            />
                          ) : (
                            <div className="wrapper_page_xs">
                              <TitleSection
                                title={block.field_title?.processed}
                                subtitle={block.field_subtitle?.processed}
                                description={block.body?.processed}
                                type="arrow"
                                color={getTextColor(getSectionBG(block.field_backgroud_color))}
                              />
                              <div className={classNames('only_desktop')}>
                                <Carousel
                                  slides={getReOrderedCarouselSlides(sliders)}
                                  sliderMode=""
                                  textColor={getTextColor(getSectionBG(block.field_backgroud_color))}
                                  openVideo={loadVideo}
                                  socialShareIcons={pageContext['config_data_' + intl.locale]}
                                  only_images={block.field_slider_only_images}

                                />
                              </div>
                            </div>
                          )
                        }
                      </div>

                      <div className={classNames('only_mobile_tablet')}>
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            subtitle={block.field_subtitle?.processed}
                            description={block.body?.processed}
                            type="arrow"
                            color={getTextColor(getSectionBG(block.field_backgroud_color))}
                          />
                        </div>
                        <SliderComponent settings={slider_settings} arrowColor={getTextColor(getSectionBG(block.field_backgroud_color))}>
                          {sliders.map((slide, j) => (
                            <div key={j}>
                              {slide?.image && (
                                <img
                                  src={processUrl(slide.image?.image_style_uri?.gatsby_medium)}
                                  alt={slide.alt}
                                  style={{
                                    width: '100%',
                                    height: '123vw',
                                    maxHeight: '510px',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}
                              {
                                !block.field_slider_only_images &&
                                <div className={classNames('info_title_description', `color_${getTextColor(getSectionBG(block.field_backgroud_color))}`)}>
                                <p>{slide.info.text}</p>
                                {slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                    aria-label={slide.info.link_label}
                                  />
                                )}
                              </div>
                              }
                       
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_solutions':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}`;
                return <section
                  className={classNames(
                    'section_content',
                    'block_content__block_solutions',
                    block?.field_title?.processed ? 'scroll_to_' + sectionCount : ''
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                  >
                    <CardSection
                      imagesArray={imagesArray}
                      data={block}
                      color={getTextColor(getSectionBG(block.field_backgroud_color))}
                      bgColor={getSectionBG(block.field_backgroud_color)}
                    />
                  </ShapeBackground>
                </section>;
              case 'block_content__block_contact_rail':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}`;
                block?.field_texte_multiples.forEach((field_texte) => {
                  globalText += `${field_texte?.processed}`;
                })
                return <section
                  className={classNames(
                    'section_content',
                    'block_content__block_contact_rail',
                    block?.field_title?.processed ? 'scroll_to_' + sectionCount : ''
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                  >
                    <ContactInfo
                      data={block}
                      color={getTextColor(getSectionBG(block.field_backgroud_color))}
                    />

                  </ShapeBackground>

                </section>;

              case 'block_content__block_chiffres_clefs':
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                const dataArr = [];
                block.relationships?.field_values?.forEach((item) => {
                  globalText += `${item.field_chiffre}.${item.field_legend?.processed}`;
                  dataArr.push({
                    number: item.field_chiffre,
                    title: item.field_legend?.processed,
                  });
                });

                return (
                  <KeyNumbers
                    key={i}
                    title={block.field_title?.processed}
                    subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                    sectionName={block?.field_title?.processed ? 'scroll_to_' + sectionCount : null}
                    sectionClass='block_content__block_chiffres_clefs'
                    textColor="white"
                    NoPadding={pageData.relationships?.field_blocs[i - 1]?.field_position_bordure_bas === 'droite' ? false : true}
                    behindBorder={pageData.relationships?.field_blocs[i - 1]?.field_position_bordure_bas === 'droite' ? true : false}
                    backgroundImg={getImage(
                      imagesArray,
                      block.relationships?.field_image?.drupal_internal__mid
                    )}
                    datas={dataArr}
                    locale={block.langcode}
                  />
                );

              case 'block_content__block_cta_avec_image_de_fond':
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_cta_avec_image_de_fond',
                      { 'collapse_with_bottom_section': block?.field_no_padding }
                    )}
                    key={i}
                  >
                    <div className="wrapper_page">
                      <DiscoverOfferBox
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image
                            ?.drupal_internal__mid
                        )}
                        alt={block?.relationships?.field_image?.field_media_image?.alt || ''}
                        label={block?.field_link?.title}
                        link={block?.field_link?.url}
                        target={
                          block?.field_nouvelle_fenetre ? '_blank' : '_self'
                        }
                      />
                    </div>
                  </section>
                );

              case 'block_content__block_video':

                const videoContent = {
                  content: [
                    {
                      type: 'video',
                      link: block.field_link?.uri,
                      title: removeHtmlTag(block.field_title?.processed),
                      image: getImage(
                        imagesArray,
                        block.relationships?.field_image?.drupal_internal__mid
                      ),
                      image_alt: block.relationships?.field_image?.field_media_image?.alt || '',
                      description: block.body?.processed
                    },
                  ],
                };
                return <VideoSection
                  key={i}
                  imagesArray={imagesArray}
                  videoDetails={videoContent}
                  customClass={block?.field_title?.processed ? 'scroll_to_' + sectionCount : null}
                  sectionClass='block_content__block_video'
                ></VideoSection>;

              case 'block_content__block_document':
                let tempFileArr = [];
                block.relationships?.field_file.forEach((file) => {
                  tempFileArr.push(
                    getDocument(documentsArray, file?.drupal_internal__mid)
                  );
                });
                const documentContent = {
                  year: block.node?.relationships?.field_year?.name,
                  title: removeHtmlTag(block?.field_title?.processed),
                  subtitle: removeHtmlTag(block?.field_subtitle?.processed),
                  category: block?.field_category,
                  content: [
                    {
                      type: 'document',
                      files: tempFileArr,
                    },
                  ],
                };

                return (
                  <DocumentSection
                    documentDetails={documentContent}
                    titleSection={true}
                    hasBackground={false}
                    sectionClass='block_content__block_document'
                    customClass={block?.field_title?.processed ? 'scroll_to_' + sectionCount : null}
                    key={i}
                  ></DocumentSection>
                );

              case 'block_content__block_document_avec_titre':
                let allDocDetails = [];
                block.relationships?.field_document_block?.forEach((doc) => {
                  let filesArr = [];
                  doc.relationships?.field_file?.forEach((file) => {
                    filesArr.push({
                      name: getDocument(
                        documentsArray,
                        file?.drupal_internal__mid
                      )?.description,
                      size: `(.pdf ${formatBytes(
                        getDocument(documentsArray, file?.drupal_internal__mid)
                          ?.filesize
                      )})`,
                      link: getDocument(
                        documentsArray,
                        file?.drupal_internal__mid
                      )?.uri.url,
                    });
                  });

                  allDocDetails.push({
                    title: doc.field_title?.processed,
                    subTitle: doc.field_subtitle?.processed,
                    linkTitle: doc.field_lien?.title,
                    link: doc.field_lien?.url,
                    files: filesArr,
                    background: doc.field_background
                  });
                });

                return (
                  <section
                    className={classNames(
                      'section_content',
                      block.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                      'block_content__block_document_avec_titre',


                    )}
                    key={i}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection title={block.field_title?.processed} />
                      {allDocDetails.map((document, k) => {

                        return (
                          <div
                            className={classNames(
                              'document_type',
                              document.background ? 'hasBackground' : ''
                            )}
                            style={{ marginBottom: '20px' }}
                            key={k}
                          >
                            <TitleBlock title={document.title} />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: document.subTitle,
                              }}
                            ></div>
                            {document.linkTitle && (
                              <Button
                                label={document.linkTitle}
                                link={document.link}
                                target={
                                  document.link?.indexOf('.pdf') > 0
                                    ? '_blank'
                                    : '_self'
                                }
                              />
                            )}
                            {
                              document.files?.length > 0 && <DocumentView data={document.files} />
                            }

                          </div>
                        );
                      })}
                    </div>
                  </section>
                );
              case 'block_content__block_nos_valeurs':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}.${block.body?.processed}`;

                return (
                  <section
                    className={classNames(
                      'section_content',
                      'section_our_values',
                      'block_content__block_nos_valeurs',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : '',
                      block.field_secondary_background ? 'zero_margin_bottom' : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block.field_backgroud_color)}
                      bottomInfinite={block.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                      secondaryColor={getSectionBG(block.field_secondary_background)}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          type="arrow"
                          color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                        />
                      </div>
                      <VisuelTextOverlappedBlock
                        extremeLeft={true}
                        visuel={getImage(
                          imagesArray,
                          block.relationships?.field_image?.drupal_internal__mid
                        )}
                        alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                        bgColor="color_bleu_sky"
                        text={block.body?.processed}
                      />
                      <div className="wrapper_page_xs">
                        {block.relationships.field_values.map((textBlock, j) => {
                          globalText += `${textBlock.field_bloc_text_1?.processed != undefined &&
                            textBlock.field_bloc_text_1?.processed != null
                            ? textBlock.field_bloc_text_1?.processed
                            : ''
                            }.${textBlock.field_bloc_text_2?.processed != undefined &&
                              textBlock.field_bloc_text_2?.processed != null
                              ? textBlock.field_bloc_text_2?.processed
                              : ''
                            }`;
                          return (
                            <BlockValuesTwoCol
                              isLast={block.relationships?.field_values?.length - 1 == j ? true : false}
                              textBlock={textBlock}
                              key={uniqueID(j)}
                              color={getSectionBG(block.field_backgroud_color)}
                            />
                          );
                        })}
                      </div>
                      {block.relationships?.field_image_2
                        ?.drupal_internal__mid && (
                          <div className="bottom_visuel">
                            <div className="visuel">
                              <img
                                src={
                                  getImage(
                                    imagesArray,
                                    block.relationships?.field_image_2
                                      ?.drupal_internal__mid
                                  )?.uri?.url
                                }
                                alt={block.relationships?.field_image_2?.field_media_image?.alt || ''}
                                style={{ height: '100%', objectFit: 'cover' }}
                                aria-hidden='true'
                              />
                            </div>
                          </div>
                        )}
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_notre_origine':
                globalText += `${block.field_block_text?.processed != undefined &&
                  block.field_block_text?.processed != null
                  ? block.field_block_text?.processed
                  : ''
                  }.${block.field_bloc_text_2?.processed != undefined &&
                    block.field_bloc_text_2?.processed != null
                    ? block.field_bloc_text_2?.processed
                    : ''
                  }`;
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_notre_origine',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block.field_backgroud_color)}
                      bottomInfinite={block.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    >
                      <div className="wrapper_page_xs">
                        <BlockTextTwoColButton
                          title={{
                            type: 'section_title',
                            text_title: block.field_title?.processed,
                            subtitle: block.field_subtitle?.processed,
                            color: getTextColor(getSectionBG(block?.field_backgroud_color))
                          }}
                          section_marge={false}
                          body_text_1={block.field_block_text?.processed}
                          body_text_2={block.field_bloc_text_2?.processed}
                          cta={{
                            label: block.field_link?.title,
                            primary: true,
                            link: block.field_link?.url,
                            cta_position: 'center'
                          }}

                        />
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_texte_with_image':
                globalText += block.field_title?.processed || '';
                globalText += block.field_subtitle?.processed || '';
                for (i = 0; i < block.relationships.field_section?.length; i++) {
                  globalText += block.relationships.field_section[i]?.field_title?.processed || '';
                  globalText += block.relationships.field_section[i]?.field_text?.processed || '';
                }
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_texte_with_image',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          color={getTextColor(getSectionBG(block.field_backgroud_color))}
                        />
                      </div>
                      <div className="only_mobile">
                        <SliderComponent
                          settings={slider_why_join_settings}
                          arrowColor={getTextColor(getSectionBG(block.field_backgroud_color))}
                        >
                          {block?.relationships?.field_section?.map((whyToJoinItem, i) => (
                            <div key={i}>
                              <div className="visuel">
                                <img
                                  src={getImage(
                                    imagesArray,
                                    whyToJoinItem?.relationships?.field_image?.drupal_internal__mid
                                  )?.image_style_uri?.gatsby_medium_carousel}
                                  alt={whyToJoinItem.field_title?.processed}
                                  style={{ height: '74vw', width: '100%' }}
                                />
                              </div>
                              <div className="upper_text">
                                <h5
                                  className="title_hash"
                                  dangerouslySetInnerHTML={{
                                    __html: whyToJoinItem.field_title?.processed,
                                  }}
                                ></h5>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: whyToJoinItem.field_text?.processed,
                                  }}
                                ></div>
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                      <div className="only_tablet_desktop wrapper_page_xs">
                        {block.relationships?.field_section?.map((whyToJoinItem, index) => (
                          <TextVisuelTwoCol
                            key={index}
                            orderInverse={
                              whyToJoinItem.field_alignment === 'right'
                                ? true
                                : false
                            }
                            extremeLeft={
                              whyToJoinItem.field_alignment === 'left'
                                ? true
                                : false
                            }
                            extremeRight={
                              whyToJoinItem.field_alignment === 'right'
                                ? true
                                : false
                            }
                            visuel={getImage(
                              imagesArray,
                              whyToJoinItem?.relationships?.field_image?.drupal_internal__mid
                            )}
                            alt={whyToJoinItem?.field_title?.processed}
                            title={whyToJoinItem?.field_title?.processed}
                            titleHash={false}
                            text={whyToJoinItem?.field_text?.processed}
                            colorTitle={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                            colorDescription={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                            titleIconColor={whyToJoinItem.field_theme_title === 'theme_1' ? 'color_yellow' : (getSectionBG(block?.field_backgroud_color) === 'bleu' ? 'color_yellow' : 'color_dark_bleu')}
                          />
                        ))}
                      </div>
                    </ShapeBackground>
                  </section>

                )
              case 'block_content__block_gouvernance':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}`;
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_gouvernance',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          color={getTextColor(getSectionBG(block.field_backgroud_color))}
                        />
                        {block.relationships?.field_blocs?.map((compose, l) => {
                          globalText += `${compose.field_title?.processed != undefined &&
                            compose.field_title?.processed != null
                            ? compose.field_title?.processed
                            : ''
                            }.${compose.field_bloc_text_1.processed != undefined &&
                              compose.field_bloc_text_1.processed != null
                              ? compose.field_bloc_text_1.processed
                              : ''
                            }.${compose.field_bloc_text_2.processed != undefined &&
                              compose.field_bloc_text_2.processed != null
                              ? compose.field_bloc_text_2.processed
                              : ''
                            }`;
                          return (
                            <CommitteeDetail
                              key={l}
                              title={compose.field_title?.processed}
                              description={compose.field_bloc_text_1.processed}
                              composeTxt={compose.field_bloc_text_2.processed}
                              color={getTextColor(getSectionBG(block.field_backgroud_color))}
                            />
                          );
                        })}
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_conseil_administration':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}`;
                let list_board_of_directors = [];
                block.relationships?.field_biographies?.forEach((biography) => {
                  globalText += `${biography.title}.${biography.field_poste}.`;
                  list_board_of_directors.push({
                    visuel: getImage(
                      imagesArray,
                      biography?.relationships?.field_image?.drupal_internal__mid
                    ),
                    alt: biography.relationships?.field_image?.field_media_image?.alt || '',
                    fullname: biography.title,
                    post: biography.field_poste,
                    link: '/' + biography.path?.langcode + biography.path?.alias,
                    link_label: block?.field_link_title,
                  });
                });
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_conseil_administration',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : ''
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          description={block.field_subtitle?.processed}
                          forceTagH5={true}
                        />
                      </div>
                      <div className="wrapper_page">
                        <ListAdminMembers listAdmins={list_board_of_directors} />
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_direction':
                let directionMembers = [];
                let detailTxt = '';
                block.relationships?.field_biographies?.forEach((biography) => {
                  detailTxt =
                    biography.body?.summary != '' &&
                      biography.body?.summary != undefined
                      ? '<p>' + biography.body?.summary + '</p>'
                      : biography.body?.processed;
                  globalText += `${biography.title}.${biography.field_poste}.${biography.field_date_de_naissance}.${detailTxt}.`;
                  directionMembers.push({
                    visuel: getImage(
                      imagesArray,
                      biography?.relationships?.field_image?.drupal_internal__mid
                    ),
                    alt: biography.relationships?.field_image?.field_media_image?.alt || '',
                    name: biography.title,
                    post: biography.field_poste,
                    dob: biography.field_date_de_naissance,
                    textDetails: detailTxt,
                  });
                });
                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_content__block_direction',
                      block?.field_title?.processed ? 'scroll_to_' + sectionCount : null
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={tradDroiteGauche(block?.field_position_bordure_haut)}
                      color={getSectionBG(block?.field_backgroud_color)}
                      bottomInfinite={block?.field_infinite_border}
                      bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                        />
                      </div>
                      <div className="wrapper_page_xs">
                        <div className="btn_container">
                          {block.field_lien_multiple?.map((button, j) => {
                            return (
                              <Button
                                key={j}
                                label={button.title}
                                link={button.url}
                                target={
                                  button.url.indexOf('.pdf') > 0
                                    ? '_blank'
                                    : '_self'
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                      <ListDirectionMember
                        listDirectionMember={directionMembers}
                        textColor={getTextColor(getSectionBG(block?.field_backgroud_color))}
                      />
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_nous_rejoindre':
                globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed}.${block.body?.processed}`;
                return <HomeJoinUs
                  sectionClass='block_content__block_nous_rejoindre'
                  data={block}
                  key={i}
                  customClass={block?.field_title?.processed ? 'scroll_to_' + sectionCount : null}
                />;

              case 'block_content__block_engagement':
                const rseList = block.field_bloc_text_1?.map((c) => c.processed);
                rseList?.forEach((commitment) => {
                  globalText += `.${commitment}`;
                })
                return <ListEngagement
                  sectionClass='block_content__block_engagement'
                  title={block.field_offre_text}
                  key={i}
                  commitments={rseList}
                  customClass={null}
                />
              default:
                break;
            }
          })}
          {
            pageData.field_display_form_innovation &&
            <section className={classNames('section_content', 'section_contact_form')}>
              <div className="wrapper_page">
                <div style={{ textAlign: 'center' }}>
                  <TitleContact
                    removeicon={true}
                    title={intl.formatMessage({
                      id: 'contactexpertise.form.title',
                    })}
                  />
                </div>
                <div className="col" style={{ 'width': '350px', 'margin': '0 auto' }}>
                  <div className="contact_form_component_container">
                    <ContactFormDefaultPage title={pageData.title} formName="contact_innovation" langCode={pageData.langcode} />
                  </div>
                </div>
              </div>
            </section>
          }
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query defaultTemplateQuery(
    $locale: String!
    $slug: String!
  
    $documentsID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_type
          field_padding
          field_sous_titre
          field_version_audio
          field_progress
          field_display_dropdown_list
          field_display_form_innovation
          field_text_2{processed}
          field_header_inverse
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          path {
            alias
            langcode
          }
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_gouvernance {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  relationships {
                    field_blocs {
                      field_bloc_text_1 {
                        processed
                      }
                      field_bloc_text_2 {
                        processed
                      }
                      field_title {
                        processed
                      }
                    }
                  }
                }
                ... on block_content__block_conseil_administration {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_link_title
                  relationships {
                    field_biographies {
                      body {
                        processed
                      }
                      field_poste
                      field_date_de_naissance
                      title
                      path {
                        langcode
                        alias
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_direction {
                  field_lien_multiple {
                    url
                    title
                  }
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  relationships {
                    field_biographies {
                      body {
                        processed
                        summary
                      }
                      field_poste
                      field_date_de_naissance
                      title
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_contact {
                  field_type_bloc_contact
                  field_nouvelle_fenetre
                  body {
                    processed
                  }
                  field_link {
                    url
                  }
                }
                ... on block_content__block_edito {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                  field_citation
                  field_link_title
                }
                ... on block_content__block_expertise {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_bloc_text_1 {
                    processed
                  }
                  field_position_bordure_bas
                  field_position_bordure_haut
                  field_infinite_border
                  field_backgroud_color
                }
                ... on block_content__basic {
                  id
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_no_padding
                  field_alignment
                  field_appearance
                  field_subtitle {
                    processed
                  }
                  field_title_type
                  field_title {
                    processed
                  }
                  body{
                    processed
                  }
                  field_legend {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_sites_web {
                      field_text {
                        processed
                      }
                      field_subtitle
                      field_link {
                        url
                      }
                      field_theme_style
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                  field_link {
                    title
                    url
                  }
                }
                ... on block_content__block_texte {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_section {
                      field_no_padding
                      field_alignment
                      field_theme_title
                      field_nouvelle_fenetre
                      field_title {
                        processed
                      }
                      field_bloc_text_1 {
                        processed
                      }                
                      field_link {
                        title
                        url
                      }
                      field_nouvelle_fenetre
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_cta {
                  field_title {
                    processed
                  }
                  field_link {
                    url
                    title
                  }
                  field_category
                  field_nouvelle_fenetre
                  relationships {
                    field_media_category {
                      name
                      drupal_internal__tid
                    }
                  }
                }
                ... on block_content__block_citation {
                  field_citation
                  langcode
                }
                ... on block_content__block_slider_nos_activites {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_link_title
                  field_infinite_border
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__page{
						              title
                          body{
						                summary
                          }
                          path{
                            alias
                            langcode
                          }
                          relationships{
                            field_image{
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_slider_innovation {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_slider_big
                  field_slider_only_images
                  relationships {
                    field_slides {
                      field_title {
                        processed
                      }
                      field_link{
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                        field_anchor {
                          drupal_internal__tid
                        }
                      }
                    }
                  }
                  
                }
                ... on block_content__block_solutions{
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_backgroud_color
                  relationships{
                    field_blocs{
                      field_subtitle
                      field_text{
                        processed
                      }
                      field_bloc_text_1{
                        processed
                      }
                      relationships{
                        field_image{
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                          field_alt
                        }
                      }
                      field_link{
                        title
                        url
                      }
                    }
                  }
                  
                }
                ... on block_content__block_contact_rail{
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_texte_multiples{
                    processed
                  }
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  langcode
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }
                ... on block_content__block_video {
                  id
                  field_title {
                    processed
                  }
                  field_link {
                    uri
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_nous_rejoindre {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_aria_label
                  drupal_internal__id
                  field_offre_text
                  field_link {
                    title
                    url
                  }
                  field_chiffre_offre
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                }
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_background
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_document_avec_titre {
                  field_title {
                    processed
                  }
                  relationships {
                    field_document_block {
                      field_title {
                        processed
                      }
                      field_subtitle {
                        processed
                      }
                      field_lien {
                        title
                        url
                      }
                      field_background
                      relationships {
                        field_file {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_nos_valeurs {
                  body {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_secondary_background
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_values {
                      field_bloc_text_1 {
                        processed
                      }
                      field_bloc_text_2 {
                        processed
                      }
                    }
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_image_2 {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_notre_origine {
                  id
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_block_text {
                    processed
                  }
                  field_bloc_text_2 {
                    processed
                  }
                  field_link {
                    title
                    url
                    uri
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                }
                ... on block_content__block_cta_avec_image_de_fond {
                  field_link {
                    title
                    uri
                    url
                  }
                  field_nouvelle_fenetre
                  field_no_padding
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_texte_with_image{
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_backgroud_color
									field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  relationships{
                    field_section{
                      __typename
                      ... on paragraph__simple_sections{
                      field_title{
                        processed
                      }
                      field_text{
                        processed
                      }
                      field_alignment
                      relationships {
                        field_image {
                          drupal_internal__mid
                            field_media_image{
                              alt
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_engagement {
                  field_offre_text
                  field_bloc_text_1 {
                    processed
                  }
                }

                ... on block_content__block_banniere_evenementielle{
									body {
                  
                      processed
                    }
                    field_lien {
                      title
                      url
                    }
                    field_aria_label
                    field_display_logo
                    field_display
                    langcode
                    relationships {
                      field_image {
                        drupal_internal__mid
                        field_media_image{
                          alt
                        }
                      }
                    }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
      
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
  }
`;

export default DefaultTemplate;

import React from 'react'
import TitleSection from '../../Molecules/TitleSection/TitleSection'
import './BlockExpertise.scss'
let classNames = require('classnames');
const BlockExpertise = ({ title, subtitle, text, textList, color = 'dark_bleu' }) => {
    return (
        <div className='blockexpertise'>

            <div className="wrapper_page_xs">
                <TitleSection
                    title={title}
                    subtitle={subtitle}
                    color={color}
                />
                <div className='flex_content'>
                    <div className={classNames('txt-wrapper', 'color_' + color)} dangerouslySetInnerHTML={{ __html: text }} />
                </div>

                <div className='bullet_wrapper'>
                    {
                        textList.map((list, index) => {
                            return (
                                <div className={classNames('list-wrap', 'color_' + color)} dangerouslySetInnerHTML={{ __html: list.processed }} key={index}>
                                </div>
                            )
                        })

                    }
                </div>

            </div>

        </div>
    )
}

export default BlockExpertise
import React from 'react';
import { Link } from "gatsby"

import PropTypes from 'prop-types';
import './LinkButton.scss';

const LinkButton = ({ target, inactive, label, link, customClass, ariaLabel }) => {
  const active = inactive ? 'inactive' : null;
  return (
    <>
      {
        target === '_blank' ? (
          <a
            href={link}
            className={['linkbutton', active, customClass].join(' ')}
            {...ariaLabel && { 'aria-label': ariaLabel }}
          >
            {label}
          </a>
        ) : (
          <Link
            to={link}
            className={['linkbutton', active, customClass].join(' ')}
            {...ariaLabel && { 'aria-label': ariaLabel }}
          >
            {label}
          </Link>
        )
      }
    </>
  );
};

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
};

LinkButton.defaultProps = {
  label: 'ButtonLink'
};

export default LinkButton;

import React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';

import './DirectionBlock.scss';

let classNames = require('classnames');

const DirectionBlock = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  name,
  post,
  dob,
  textDetails,
  mobile,
  textColor,
}) => {
  const { processUrl } = useMedias();

  return (
    <div className={classNames('direction_block_component', 'color_' + textColor)}>
      {mobile ? (
        <div className="text_visuel_mobile">
          <div className={classNames('visuel')}>
            <div className="visuel_square">
              <img
                src={processUrl(visuel?.image_style_uri?.gatsby_profile_large)}
                alt={alt}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>
          <div className="basic_info">
            <p className="name">{name}</p>
            <p className="post">{post}</p>
            <p className="dob">{dob}</p>
          </div>
          <div
            className="details"
            dangerouslySetInnerHTML={{ __html: textDetails }}
          ></div>
        </div>
      ) : (
        <div
          className={classNames({
            cross_wrapper_extreme_right: extremeRight,
            cross_wrapper_extreme_left: extremeLeft,
          })}
        >
          <div
            className={classNames('text_visuel', {
              order_inverse: extremeRight,
            })}
          >
            <div className={classNames('visuel')}>
              <div className="visuel_square">
                <img
                  src={processUrl(visuel?.image_style_uri?.gatsby_profile_large)}
                  alt={alt}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
            <div className={classNames('text_container')}>
              <div className={classNames('text_center')}>
                <div className="basic_info">
                  <p className="name">{name}</p>
                  <p className="post">{post}</p>
                  <p className="dob">{dob}</p>
                </div>
                <div
                  className="details"
                  dangerouslySetInnerHTML={{ __html: textDetails }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DirectionBlock.defaultProps = {
  text: 'Default Description Description Description Description Description Description Description',
  alt: '',
};

DirectionBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool,
};

export default DirectionBlock;
